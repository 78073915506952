<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row v-if="!fetchingData">
      <v-col cols="12" md="8">
        <v-card tile>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td style="width: 150px">Titel</td>
                  <td>
                    <Patcher
                      :itemValue="data.title"
                      itemName="title"
                      :route="patchRoute"
                      inputElement="input"
                      inputType="text"
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.title"
                      @emit="
                        data.title = $event.value;
                        pageTitle = $event.value;
                      "
                    ></Patcher>
                  </td>
                </tr>

                <tr>
                  <td style="width: 150px">Bild</td>
                  <td>
                    <Patcher
                      :itemValue="data.mediaUuid"
                      itemName="mediaUuid"
                      itemLabel="Bild"
                      :hideLabel="false"
                      :route="patchRoute"
                      inputElement=""
                      inputType="media"
                      :previewValue="data.mediaUuid"
                      @emit="data.mediaUuid = $event.value"
                    ></Patcher>
                  </td>
                </tr>

                <tr>
                  <td style="width: 150px">Aufgabenstellung</td>
                  <td>
                    <Patcher
                      :itemValue="data.task"
                      itemName="task"
                      itemLabel="Beschreibung"
                      :hideLabel="false"
                      :route="patchRoute"
                      inputElement="textarea-md"
                      inputType=""
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.task"
                      @emit="data.task = $event.value"
                    ></Patcher>
                  </td>
                </tr>

                <tr>
                  <td style="width: 150px">Antwortmöglichkeiten</td>
                  <td>
                    <Patcher
                      :itemValue="data.answerOptions"
                      itemName="answerOptions"
                      :route="patchRoute"
                      inputElement="input"
                      inputType="text"
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.answerOptions"
                      @emit="data.answerOptions = $event.value"
                    ></Patcher>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn depressed color="primary" disabled>
              Vorschau
              <v-icon right dark> mdi-open-in-new </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher";
export default {
  name: "Game",

  components: {
    PageHeader,
    Patcher,
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  data() {
    return {
      pageTitle: null,
      pageDescription: "Hier werden alle Bilderrätsel-Spiele verwaltet.",
      itemId: this.$route.params.id,
      fetchingData: false,
      data: null,
      patchRoute: `picture_puzzle/${this.$route.params.id}`,
    };
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(`picture_puzzle/${this.itemId}`);

      if (response.status === 200) {
        this.data = response.data.data;
        this.pageTitle = this.data.title;
      }

      this.fetchingData = false;
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.$router.push({
          name: "tech.page-plugins.picture_puzzle.:id",
          params: { id: value.itemId },
        });
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>

<style scoped>
.is-relative {
  position: relative;
}
#error-map {
  width: 100%;
  height: auto;
  background-color: #eee;
}

.tile {
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.tile-highlight {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5);
}

img.bad-image {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  object-fit: contain;
}
</style>
